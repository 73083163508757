@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
  font-size: 14px;
} */

.react-datepicker__input-container input {
  @apply block w-full text-base border border-gray-300 rounded-lg shadow-sm form-input md:text-sm bg-neutral-background;
}

.react-datepicker__tab-loop {
  @apply absolute;
}

.react-datepicker-popper {
  @apply absolute top-1 z-10 left-0 w-72 text-sm transform-none bg-neutral-background shadow border border-primary-backgroundSubtle rounded-md px-3 py-2 !important;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-xs font-medium text-center text-gray-800;
}

.react-datepicker__day-name {
  @apply flex items-center justify-center w-8 h-8 py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply flex items-center justify-center w-8 h-8 transition rounded right-12 hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply flex items-center justify-center w-8 h-8 transition rounded right-4 hover:bg-gray-200;
}

.react-datepicker__day {
  @apply flex items-center justify-center w-8 h-8 py-1 mb-1 text-sm leading-loose text-gray-700 transition rounded hover:bg-gray-200;
}

.react-datepicker__day--disabled {
  @apply opacity-50 cursor-not-allowed hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-primary-backgroundSubtle;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 bg-neutral-background border-primary-backgound;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 bg-neutral-background border-primary-backgound;
}

.react-datepicker__day--selected {
  @apply text-neutral-textOnDark bg-primary-backgound hover:bg-primary-actionHover;
}

.react-datepicker__day--range-start {
  @apply text-neutral-textOnDark bg-primary-backgound hover:text-gray-700 hover:bg-neutral-background;
}
a .react-datepicker__day--range-end {
  @apply text-neutral-textOnDark bg-primary-backgound hover:text-gray-700 hover:bg-neutral-background;
}
